import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'


const ArticlePage = ({location, data}) =>
  (
    <Layout>

      <SEO title="Summon launches Australia’s first eCommerce service for prestige car owners"
        description="Auto technology startup Summon has launched Australia’s first full ecommerce service for prestige cars, offering enthusiasts a completely contactless way to buy and sell vehicles privately."
        location={location}
      />

      <PageHeader
        title="Summon launches Australia’s first eCommerce service for prestige car owners"
        text="Auto technology startup Summon has launched Australia’s first full ecommerce service for prestige cars, offering enthusiasts a completely contactless way to buy and sell vehicles privately."
      />

      <SectionPageMargin>

          <Heading2>The new ecommerce service digitises the entire car buying process.</Heading2>

          <Paragraph1>
            The new offering, which is available in Melbourne, Sydney, Gold Coast and Brisbane, builds on Summon’s existing concierge service, which focuses on cars valued over $50,000. Since launching in 2020 they have sold over 85 cars to the total value of $8 million. 
          </Paragraph1>

          <Paragraph1>
            The new ecommerce service digitises the entire car buying process. It replaces a test drive -- which is not possible during lockdowns -- with a variety of other digital alternatives and a money-back guarantee on delivery. Until now, this has not been available to private sellers, only to car dealers and auction houses.
          </Paragraph1>

          <Paragraph1>
            The launch has capitalised on the increase in prices of used cars, which due to supply constraints triggered by the COVID-19 pandemic have risen 34% in the past 12 months according to the latest Moody Analytics data. The average price of cars sold on the Summon platform is $94,000. 
          </Paragraph1>

    
          <Img fluid={data.ecommLaunch.childImageSharp.fluid} style={{ marginTop: '60px', marginBottom: '60px' }} alt="Australia’s first full ecommerce service" />
   

        The eCommerce service includes: 
          <ul>
            <li>Up to 100 professional photos of each car listed with shots of the interior, exterior and engine. </li>
            <li>A Redbook Remote Inspection of the car - an independent quality check for the car. </li>
            <li>A live virtual inspection of the vehicle with the buyer and seller.</li>
            <li>Virtual completion of the deal via phone and email.</li>
            <li>Either contactless-delivery of the vehicle or click and collect alternative.</li>
            <li>A Money Back Guarantee if the buyer is not completely satisfied with the vehicle on final inspection.</li>

          </ul>
 
          <Paragraph1>
            “This new Australian-first service is the safest most comprehensive way to buy a prestige car privately during lockdown,” <strong>Tim O’Neill, co-founder and CEO of Summon</strong> said. 
          </Paragraph1>

          <Paragraph1>
            “Selling your car privately is a fantastic alternative to just selling your car to a dealer and losing  on average 15% of the value of your asset. We manage the entire process for the seller, and work with them to achieve the best possible value for their car.”
          </Paragraph1>

          <Paragraph1>
            “For buyers, there is no better way on the market to buy a prestige car privately during lockdown. We’ve worked hard to ensure the service is not only safe, but comprehensive, covering everything you need to know while offering you the ability to ask for more information or clarification at any time.
          </Paragraph1>

          <Paragraph1>
            “Dealers and OEMs are starting to provide ways to sell cars online due to ongoing lockdowns, but 50 per cent of used cars in Australia are sold privately. We saw there was a gap in providing a safe way to sell cars online for people who wish to sell their car privately.”
          </Paragraph1>

          <Paragraph1>
            “Last year’s lockdowns triggered this change in approach for Summon, and now we’re ahead of the curve in assisting the private buying and selling of prestige cars, in what is a logistically difficult period to do so.”
          </Paragraph1>

      </SectionPageMargin>

     <ReadyToGetStarted />

    </Layout>

  )

export default ArticlePage


export const query = graphql`
	query  {

    ecommLaunch: file(relativePath: { eq: "articles/ecom-pr-launch.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
	
	}
`

